// import React from "react";
// import Button from 'react-bootstrap/Button';
// import topbarLogo from '../../../Images/lifeskylls-logo.png';
// import banner from '../../../Images/landing-banner.PNG';

// const LandingPage = () => {

//     return(
//         <div className='wrapper flext-grow-1'>
//         <div className="App">
               
//         <nav className="navbar navbar-expand-lg navbar-light header-color">
//         <div className="container-fluid">
//         <img src={topbarLogo} className="topBarLogo" />
      
//         <a className="navbar-brand" href="#"></a>
//         <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//             <span className="navbar-toggler-icon"></span>
//         </button>
//         <div className="collapse navbar-collapse" id="navbarSupportedContent">
//         <div className="navbar-nav me-auto mb-2 mb-lg-0">
        
//         </div>
      
//         <form className="d-flex"> 
//         {/* <Link to={"/login"} onClick={handleLogout} className="nav-link">
//           Logout
//         </Link>   */}
//          [A product of SOMOTUS]
//         </form>
//       </div>
//     </div>
//       </nav>
 

//                 <div className='landingcontainerflex'>  
//                  <div className='landingflexitem1'>
//                     <span className='landing-h1'>
//                     A Powerful L & D product for Corporate and Educational Institutes
//                     </span>
//                     <span className='landing-h2'>
//                     Thrive in life and work with social emotional learning and 21st century skills.
//                     </span>
   
//                     <span className='landing-h3'>
//                     LIFE SKYLLS is a SAAS product startup that helps young professionals learn the skills they need to succeed in careers and life. The product will have access to byte-sized courses, workshops, assessments, 1-1 mentoring, and other programs to learn ‘critical thinking, emotional intelligence & empathy, resilience, self-management, diversity & inclusion, problem-solving, and other workplace skills’.

//                     </span>
 
//                     <span className='landing-h4'>
//                         <Button type="submit" className='landing-button'>JOIN WAITLIST</Button> &nbsp;
//                         <Button type="submit" className='btn btn-primary landing-button'>LEARN MORE</Button>
//                     </span>
                    

//                  </div>
//                  <div className='landingflexitem2'>
//                  <img src={banner} className="banner" />
//                   </div>
//                 </div>
                
//             </div>
//     </div>
        
//     );
// }

 
// export default LandingPage;
// LandingPage.jsx

import React from "react";
import Button from 'react-bootstrap/Button';
import topbarLogo from '../../../Images/lifeskylls-logo.png';
import banner from '../../../Images/landing-banner.PNG';
import  {Link}  from 'react-router-dom' 

const LandingPage = () => {

    return (
        <div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
        data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
            <div className="App App-height" style={{minHeight:'600px'}}>

                <nav className="navbar navbar-expand-lg navbar-light header-color">
                    <div className="container-fluid">
                        <img src={topbarLogo} className="topBarLogo" />

                        <a className="navbar-brand" href="#"></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="navbar-nav me-auto mb-2 mb-lg-0">

                            </div>

                            <form className="d-flex">
                                [A product of LifeSkylls]
                            </form>
                        </div>
                    </div>
                </nav>

                <div className='landingcontainerflex'>
                    <div className='landingflexitem1'>
                        <span className='landing-h1'>
                            A Powerful L & D product for Corporate and Educational Institutes
                        </span>
                        <span className='landing-h2'>
                          <br/>
                            Thrive in life and work with social emotional learning and 21st-century skills.
                        </span>

                        <span className='landing-h3'>
                        <br/>
                        <br/>
                            LIFE SKYLLS is a SAAS product startup that helps young professionals learn the skills they need to succeed in careers and life. The product will have access to byte-sized courses, workshops, assessments, 1-1 mentoring, and other programs to learn ‘critical thinking, emotional intelligence & empathy, resilience, self-management, diversity & inclusion, problem-solving, and other workplace skills’.
                        </span>
                        <span className='landing-h3'>
                        <br/>  <br/>
                        Our product launch is just around the corner, in about 4-6 weeks. If you&apos;re keen on being part of our pilot program, drop us a line at <a className="aLink" href ="mailto: hello@somotus.com">hello@somotus.com</a>. We&apos;ll get in touch with you and arrange a demo of our product.
                        </span>
                        <span className='landing-h4'>
                        <br/><br/>
                        
                            <Button type="submit" className='button'><a rel="noreferrer" style={{color:"white"}} target="_blank" href="http://tinyurl.com/yn54455j">Product Demo!</a></Button> &nbsp;
                            <Button type="submit" className='button'>LEARN MORE</Button>
                        </span>


                    </div>
                    <div className='landingflexitem2'>
                        <img src={banner} className="banner" />
                    </div>
                </div>
               
            </div>
        <div className="footer-New text-center">
        <div className='row'>
          <div className='col'>
          Copyright © 2024 by LifeSkylls.in
          </div>
          <div className='col'>
          <Link className='aLink' to={"/"}>
        Home        
        </Link>  | &nbsp;
          <Link className='aLink' to={"/contactus"}>
          Contact Us         
          </Link> 
              
          </div>
        </div>
             
      </div>
        </div>
 
    );
}

export default LandingPage;
