import React, { useEffect, useState,useRef  } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { PlusCircle, DashCircle } from "react-bootstrap-icons";
import { useFormik } from "formik";
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { getSurveyQuestionsByTag, getQuestionTags } from "../../../api/corporateApi";
import { PropTypes } from "prop-types";
import { debounce } from 'lodash';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";

function LibraryList(props) {
  //const { guid } = useParams();  
  const [clsName, setclsName] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [questionsList, setQuestionsList] = useState([]);
  const [questionTagsList, setQuestionTagsList] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedTagId, setSelectedTagId] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const [offset, setOffset] = useState(0);
  const limit = 5;

  const initialValues = {
    questionTags: ''
  };
  const validationSchema = Yup.object({
    questionTags: Yup.string()
      //.min(1, "At least one tag is required.")
      .required("Tags are required.")
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {} // Empty onSubmit handler
  });

 
  const fetchQuestionsData = async () => {
    try {
      const payload = {
        tagid: selectedTagId,
        offset: offset, // Use the current offset value
        limit: limit,
      };
  
      const response = await getSurveyQuestionsByTag(payload);
  
      if (response.ok) {
        // Assuming the data is in JSON format
        const responseData = await response.json();
  
        if (Array.isArray(responseData)) {
          if (offset === 0) {
            // Reset the questions list if offset is 0
            
            setQuestionsList(responseData);
           // setQuestionsList((prevQuestions) => [...prevQuestions, ...responseData]);
          } else {
            // If not 0, append to the existing list
            setQuestionsList((prevQuestions) => [...prevQuestions, ...responseData]);
          }
          //setIsEdit(false);
          setOffset((prevOffset) => prevOffset + limit); // Increment offset after fetching data
         // setOffset((prevOffset) => prevOffset + responseData.length);
        } else {
          // If the response data is not an array, handle it accordingly
          console.error('Invalid response format:', responseData);
        }
      } else {
        // Handle non-successful response (e.g., error handling)
        console.error('Error fetching questions. Status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const fetchQuestionsData1 = async () => {
    try {
      const payload = {
        tagid: selectedTagId,
        offset: 0, // Use the current offset value
        limit: limit,
      };
  
      const response = await getSurveyQuestionsByTag(payload);
  
      if (response.ok) {
        // Assuming the data is in JSON format
        const responseData = await response.json();
   
        if (Array.isArray(responseData)) {
          setQuestionsList(responseData);
          setOffset(0);
        } else {
          // If the response data is not an array, handle it accordingly
          console.error('Invalid response format:', responseData);
        }
      } else {
        // Handle non-successful response (e.g., error handling)
        console.error('Error fetching questions. Status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };
  

  const fetchQuestionsTagsData = () => {
    getQuestionTags()
      .then((response) => {
        setQuestionTagsList(response);
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  };

  useEffect(() => {
    fetchQuestionsTagsData();
    //fetchQuestionsData();
  }, [questionsList,selectedTagId]);

  const handleScroll = () => {
    // Check if the user has scrolled to the bottom and there's more data to load
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
      questionsList.length > 0
    ) {
      // Load more data when scrolled to the bottom
      fetchQuestionsData();
    }
  };

   const debouncedHandleScroll = debounce(handleScroll, 300);

  // // Use the debounced function in the useEffect hook
  // useEffect(() => {
  //   // Add debounced scroll event listener
  //   window.addEventListener('scroll', debouncedHandleScroll);
  //   return () => {
  //     // Remove debounced scroll event listener on component unmount
  //     window.removeEventListener('scroll', debouncedHandleScroll);
  //   };
  // }, [questionsList, debouncedHandleScroll]);

  const scrollableListRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled to the bottom and there's more data to load
      if (
        scrollableListRef.current &&
        scrollableListRef.current.clientHeight + scrollableListRef.current.scrollTop >=
        scrollableListRef.current.scrollHeight - 100 &&
        questionsList.length > 0
      ) {
        // Load more data when scrolled to the bottom
        fetchQuestionsData();
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 300);

    if (scrollableListRef.current) {
      // Add debounced scroll event listener
      scrollableListRef.current.addEventListener("scroll", debouncedHandleScroll);
    }

    return () => {
      // Remove debounced scroll event listener on component unmount
      if (scrollableListRef.current) {
        scrollableListRef.current.removeEventListener("scroll", debouncedHandleScroll);
      }
    };
  }, [questionsList, debouncedHandleScroll]);
   

  
  const handleSelectQuestion = (question) => {
    setSelectedQuestions((prevSelected) => [...prevSelected, question]);
  };

  const handleDeselectQuestion = (question) => {
    setSelectedQuestions((prevSelected) =>
      prevSelected.filter((selectedQuestion) => selectedQuestion !== question)
    );
  };

  const handleSearchSelection = () => {
    
    if (formik.values.questionTags === '') {
      //alert("Please select a tag.");
      setclsName('alert error');
      setSnackbarMessage('Please select a tag.');
      setShowSnackbar(true);
      return;
    }     
    setQuestionsList([]);
    setOffset(0);
     
    if (formik.isValid) {
      setSelectedTagId(formik.values.questionTags);
      fetchQuestionsData1();
    } else {
      formik.setTouched({ questionTags: true });
    }
  };

  const handleTagChange = (selectedOption) => {
    formik.setFieldValue('questionTags', selectedOption.value);
    setSelectedTagId(selectedOption.value);
  };
 
  const handleCaptureSelection = () => {
     
    if (!selectedQuestions || selectedQuestions.length === 0) {
      console.log('No selected questions found.');
      //alert("No selected questions found.");
      setclsName('alert error');
      setSnackbarMessage('No selected questions found.');
      setShowSnackbar(true);
      return;
    }
   
    //const formattedQuestions = [];
    for (const question of selectedQuestions) {
      const formattedQuestion = {
        guid:  props.surveyguid, //question.guid,
        questionText: question.text,
        questionType: question.type,
        questionMarks: question.marks,
        options: question.options && question.options.length > 0
          ? question.options.map((option) => ({
              optionText: option.text,
              correctAns: option.iscorrect,
            }))
          : [],
        questionTags: null,
        addeditStatus: 'ADD',
        parentid:question.questionid,
      };
      
      props.onMasterSubmit(formattedQuestion);
      
    }
    
   

  };
  
  
   const handleCancelQuestion = () => {
    setSelectedTagId('');
    setQuestionsList([]);
    setQuestionTagsList([]);
    fetchQuestionsTagsData();
    //formik.resetForm();
    // props.onReloadData();
    setIsEdit(false);
    
    props.onReloadData();
  };
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }

  return (
    <div className="featured-section-wrap featured-section-wrap-row">
      <div className="mainPageContainer">
        <div className="">
          <div className="">
            <div className="row">
              <div className="col">
                <Form>
                  <Form.Group className="mb-3" controlId="formTitle">
                   
                      <Select required
                          options={questionTagsList}
                          value={questionTagsList.find((option) => option.label === formik.values.questionTags)}
                          onChange={handleTagChange}
                          />
                          {formik.errors.questionTags && (
                              <div>{formik.errors.questionTags}</div>
                          )}

                  
                    <FormErrorMessage
                              fieldName="questionTags"
                              formik={formik}
                          ></FormErrorMessage>
                    <Button type="button" onClick={handleSearchSelection}>
                      Search
                    </Button>
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="scrollable-list" ref={scrollableListRef}>
                {questionsList.length > 0 ? (
                    questionsList.map((item, index) => (
                    <div key={item.questionid} style={{cursor:"pointer"}}>
                      <article className="card mt-3 flex w-full cursor-pointer flex-col rounded-lg border border-slate-300 p-2 transition-all hover:animate-pulse"
                      onClick={() =>
                        selectedQuestions.includes(item)
                          ? handleDeselectQuestion(item)
                          : handleSelectQuestion(item)
                      }
                      >
                        <p className="text-sm" style={{ margin: 0 }}>
                          {index + 1}. {item.text}
                        </p>
                        <p className="text-xs" style={{ margin: 0 }}></p>
                        <span className="mt-3 flex flex-row items-center gap-x-2" style={{ color: "#44D5E7" }} >
                        {selectedQuestions.includes(item) ? (
                          <DashCircle
                            size={24}
                            color="#4774D1"
                            style={{ position: "absolute", top: "5px", right: "5px" }}
                          />
                        ) : (
                          <PlusCircle className="primary"
                            size={24}
                            color="#4774D1"
                            style={{ position: "absolute", top: "5px", right: "5px" }}
                          />
                        )}
                        {item.options.map((itemoptions, index) => (
                          <>
                          
                        <div className="row" key={index}>
                        <div className="col" style={{ marginBottom: '5px',marginTop: '5px' }}>
                          <Form.Check
                            type={item.type === 'multiple_choice' ? 'checkbox' : 'radio'}
                            label=""
                            checked={itemoptions.iscorrect}
                            //onChange={() => handleCheckboxChange(index)}
                          />
                        </div>
                        <div className="col aLink"  style={{ marginBottom: '5px', marginTop: '5px', marginLeft: '-350px' }}>
                          {/* <Form.Control
                            required
                            type="text"
                            placeholder={`Option ${index + 1}`}
                            value={itemoptions.optionText}
                            //onChange={(e) => handleOptionTextChange(e, index)}
                          /> */}
                          <i>{itemoptions.text}</i>
                        </div>
                      
                      </div>

                          </>
                        ))}
                        </span>
                      </article>
                    </div>
                ))
              ) : (
                <p>No Questions</p>
              )}
            </div>
        {/* <button onClick={handleCaptureSelection}>Capture Selection Data</button> */}
        <Button  disabled={isEdit} variant="primary" type="submit" onClick={handleCaptureSelection}>
            Add Question
        </Button>
        <Button variant="primary" type="submit" onClick={handleCancelQuestion}>
          Cancel
        </Button>

          </div>
        </div>
      </div>
        <SnackbarProvider 
        show={showSnackbar}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        message1={clsName}
        />
    </div>
  );
}
LibraryList.propTypes ={
  onMasterSubmit: PropTypes.object.isRequired, 
  surveyguid: PropTypes.string.isRequired, 
  onReloadData:  PropTypes.object.isRequired, 
  
};
export default LibraryList;
