import { apiMthods } from "./apiUtils";
import { getToken } from "./userAuthApi";

//const baseUrl = "http://localhost/somutusAPI/v1/mentors";
//const baseUrl = "https://somotusapi.azurewebsites.net/v1/mentors"; 
//const baseUrl = "http://localhost:3001/v1/corporate"; 
//const baseUrl = process.env.NODE_ENV === 'production' ? "api/v1/corporate" : "http://localhost:3001/v1/corporate"; 
const baseUrl = process.env.NODE_ENV === 'production' ? "http://13.232.221.26/api/v1/corporate" : "http://localhost:3001/v1/corporate"; 

//const baseUrl = "http://13.232.221.26/api/v1/corporate"; 

export async function getCorporateDetails(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveCorporatedetailsbylogon", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getConstantsData(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveconstants/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization:  token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
        return response;
    });
}

export async function getMasterData(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMasterData/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
        return response;
    });
}

export async function uploadProfileImage(payload){
    const token = await getToken();
    return fetch(baseUrl + "/uploadProfileImage", {
        method: apiMthods.post,
        headers: {
            // "content-type": "application/json",
            Authorization: token,
        },
        body: payload,
    });
    // .then((response) => {
    //     if(!response.ok){
    //         const err = new Error("Error in response");
    //         throw err;
    //     } else{
    //         return response;
    //     }
    // });
}


export async function updateProfile(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updatecorporateprofile", {
        method: apiMthods.put,
        headers: {
           // "content-type": "application/json",
            Authorization: token,
        },
        body: payload, //JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // return response.json();
            return response;
        }
    });
}


export async function updateInduction(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateInduction", {
        method: apiMthods.put,
        headers: {
           // "content-type": "application/json",
            Authorization: token,
        },
        body: payload, //JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // return response.json();
            return response;
        }
    });
}

export async function updateInductionDocuments(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateInductionDocuments", {
        method: apiMthods.put,
        headers: {
           // "content-type": "application/json",
            Authorization: token,
        },
        body: payload, //JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // return response.json();
            return response;
        }
    });
}
export async function deleteFilesPhysically(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteFilesPhysically", {
        method: apiMthods.delete,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}


export async function getNotifications(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveNotificationsByLogon", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getDashboardData(){
    const token = await getToken();
    return fetch(baseUrl + "/myDashboard", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getappAccess(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveAppAccess/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
        return response;
    });
}

export async function getPayments(){
    const token = await getToken();
    return fetch(baseUrl + "/retrievePaymentsByLogon", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getConversationsByGUID(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveConversations/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function addConversations(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addConversations", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}


export async function bulkEmployeeRegistration(payload){
    
    // const token = getToken();
    // return fetch(baseUrl + "/bulkEmployeeRegistration", {
    //     method: apiMthods.post,
    //     headers: {
    //         // "content-type": "application/json",
    //         Authorization: token,
    //     },
    //     body: payload,
    // });
    const token = await getToken();
    const request = new Request(baseUrl + "/bulkEmployeeRegistration", {
      method: apiMthods.post,
      headers: {
        Authorization: token,
      },
      body: payload,
    });
  
    return fetch(request);
}

export async function getEmployees(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveEmployeesByLogon", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getAssessments(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveAssessments", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}
export async function getQuestions(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveQuestions/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function addQuestion(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addQuestion", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updateQuestion(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateQuestion", {
        method: apiMthods.put,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}
export async function deleteQuestion(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteQuestion", {
        method: apiMthods.delete,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}
export async function deleteSurveyQuestion(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteSurveyQuestion", {
        method: apiMthods.delete,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getQuestionTags(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveTags", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getAssessmentDetails(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveAssessmentDetails/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function addEditAssessment(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addEditAssessment", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getAssessmentInvites(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveInvites/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getAssessmentResults(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveAssessmentResults/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function retrieveAssessmentResultsbyguid(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveAssessmentResultsByUser/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function retrieveRewardsbyguid(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveRewards/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function publishAssessment(payload){
    const token = await getToken();
    return fetch(baseUrl + "/publishAssessment/"+payload, {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function addAssessmentInvites(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addAssessmentInvites", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    });
    // .then((response) => {
    //     if(!response.ok){
    //         const err = new Error("Error in response");
    //         throw err;
    //     } else{
    //         return response;
    //     }
    // });
}

export async function addSurveyInvites(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addSurveyInvites", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    });
    
}



export async function addNotification(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addNotifications", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

 

export async function addWorkshopsRequest(payload){
 
    const token = await getToken();
    return fetch(baseUrl + "/addWorkshopsRequest", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    })
    // .then((response) => {
    //     if(!response.ok){
    //         const err = new Error("Error in response");
    //         throw err;
    //     } else{
    //         return response;
    //     }
    // });
}

// SURVEY
export async function getSurvey(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSurvey", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}
export async function getSurveyQuestions(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSurveyQuestions/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function addSurveyQuestion(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addSurveyQuestion", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updateSurveyQuestion(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateSurveyQuestion", {
        method: apiMthods.put,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getSurveyDetails(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSurveyDetails/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function addEditSurvey(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addEditSurvey", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getSurveyInvites(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSurveyInvites/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getSurveyResults(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSurveyResults/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function publishSurvey(payload){
    const token = await getToken();
    return fetch(baseUrl + "/publishSurvey/"+payload, {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getQuestionsByTag(payload){
    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveQuestionsbyTag/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getQuestionsByTagNew(payload){
    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveQuestionsbyTagNew", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
           
            return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}


export async function getSurveyQuestionsByTag(payload){
    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSurveyQuestionsbyTag", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
           
            return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getDivisions(){
    const token = await getToken();
    return fetch(baseUrl + "/retrievedivisions", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
        return response;
    });
}

export async function addDivision(payload){
    const token = await getToken();
    return fetch(baseUrl + "/adddivision", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updateDivision(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updatedivision", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function deleteDivision(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deletedivision", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getRoles(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveroles", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
        return response;
    });
}

export async function addRole(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addrole", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updateRole(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updaterole", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function deleteRole(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleterole", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}


export async function getUsers(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveusers", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
        return response;
    });
}

export async function addUser(payload){
    const token = await getToken();
    return fetch(baseUrl + "/adduser", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updateUser(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateuser", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function deleteUser(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteuser", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getPermissions(){
    const token = await getToken();
    return fetch(baseUrl + "/retrievepermissions", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
        return response;
    });
}

export async function getSearchDetails(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSearchData/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{       
        console.log(response);
        return response;
        
    });
}

export async function getSearchDetailsByParams(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSearchDataByParams", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    });
}

export async function addRecommendations(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addRecommendations", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}


export async function sendFireBaseNotifications(payload){
    const token = await getToken();
    return fetch(baseUrl + "/SendFireBaseNotifications", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updatePassword(payload){
    const token = await getToken();
    return fetch(baseUrl + "/resetpassword", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getWorkShopLeads(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveWorkshopLeads", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}


