import React, {useEffect, useState } from "react";
import { getSurveyQuestions } from "../../../api/corporateApi";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { PropTypes } from "prop-types";

function PreviewPage(props){
    const [questionsList, setQuestionsList] = useState([]);
      
    useEffect(()=>{
       // fetchAssessentResultsData();
        fetchQuestionsData();
         
          },[]);
      
 
      const fetchQuestionsData =() => {
   
        if (props.surveyguid !== null && props.surveyguid !== undefined) {
          getSurveyQuestions(props.surveyguid).then(response=>{                    
                setQuestionsList(response); 
            })
            .catch(error => {
              // Handle error
              console.log(error);
            });
          }
        
      }
 

    return(
      <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Survey Preview
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className=''>
                <div className=''>
                {questionsList.length > 0 ? (
                questionsList.map((item, index) => (
                <div key={item.guid} style={{width:"650px;"}}>
                  <article className="card mt-3 flex w-full cursor-pointer flex-col rounded-lg border border-slate-300 p-2 transition-all hover:animate-pulse">
                    <p className="text-sm" style={{ margin: 0 }}>
                      {index + 1}. {item.questiontext}
                    </p>
                    <p className="text-xs" style={{ margin: 0 }}></p>
                    <span className="mt-3 flex flex-row items-center gap-x-2" style={{ color: "#44D5E7" }} >
                    {item.options.map((itemoptions, index) => (
                      <>
                      
                    <div className="row" key={index}>
                    <div className="col" style={{ marginBottom: '5px',marginTop: '5px' }}>
                      <Form.Check
                        type={item.type === 'multiple_choice' ? 'checkbox' : 'radio'}
                        label=""
                        checked={itemoptions.correctAns}
                        //onChange={() => handleCheckboxChange(index)}
                      />
                    </div>
                    <div className="col"  style={{ marginBottom: '5px', marginTop: '5px', marginLeft: '-700px' }}>
                      
                      <i className="aLink">{itemoptions.optionText}</i>
                    </div>
                   
                  </div>

                      </>
                    ))}
                    </span>
                  </article>
                </div>
            ))
          ) : (
            <p>No Questions</p>
          )}
                </div>
            </div>
        </div>
        </div>        
        </Modal.Body>
        </Modal>
    )

}

PreviewPage.propTypes ={
  props: PropTypes.object.isRequired, 
  surveyguid: PropTypes.string.isRequired, 
  
};
export default PreviewPage